import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import { useBreakpointValue } from '@chakra-ui/react'
import SectionsData from '../data/sections.json'

const ServicesSection = dynamic(() => import('../../components/Sections/Services'))
const ServicesSectionMobile = dynamic(() => import('../../components/Sections/ServicesMobile'))

const servicesData = SectionsData.services

const ServicesSectionContainer: FC = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return isMobile ? (
    <ServicesSectionMobile
      image={servicesData.image}
      itemsTitle={servicesData.itemsTitle}
      items={servicesData.items}
      descriptionTitle={servicesData.descriptionTitle}
      description={servicesData.description}
      actionText={servicesData.actionText}
      actionTitle={servicesData.actionTitle}
    />
  ) : (
    <ServicesSection
      image={servicesData.image}
      itemsTitle={servicesData.itemsTitle}
      items={servicesData.items}
      descriptionTitle={servicesData.descriptionTitle}
      description={servicesData.description}
      actionText={servicesData.actionText}
      actionTitle={servicesData.actionTitle}
    />
  )
}

export default ServicesSectionContainer
